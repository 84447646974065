$mobile-size: 425px;
$tablet-size: 650px;
$desktop-size: 900px;

// $desktopXL-font-size: 1400px;1900
$desktopXL-font-size: 1900px;
// $desktop-font-size: 900px;1400
$desktop-font-size: 1400px;
// $tablet-font-size: 475px;900
$tablet-font-size: 900px;

$blue: #85dfff;
$blue-opacity: #85deff59;
$black: #000000;
$green: #60FCBD;
$pink: #FF69FF;
$white: #FFFFFF;
$grey: #8B8B8A;


$light-weigth: 300;
$regular-weigth: 400;
$medium-weigth: 500;
$semibold-weigth: 600;
$bold-weigth: 700;
$extrabold-weigth: 800;
$black-weigth: 900;

$line-height: 4px;
$big-line-height: 9px;
$line-width: 298px;
$line-width-mobile: 294px;