@import './styles/variables/variables.scss';
@import './styles/fonts.scss';

* {
    font-family: 'Lexend', sans-serif !important;
}

body {
    overflow-x: hidden;
}

html {
    font-size: 14px;
}

app-home {
    overflow-x: hidden;
    position: relative;
}

//TODO borrar
.hide {
    display: none !important;
}

.pink-strikethrough {
    text-decoration: line-through;
    text-decoration-color: $pink;
    text-decoration-thickness: 3px;
}

app-root {
    // min-height: 100vh;
    flex-direction: column;
    display: flex;
    // overflow: hidden;
    justify-content: space-between;
}

.bk-gradient {
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background: linear-gradient(48deg, #d4ebfc, #e5daf6);
    background-size: 120% 120%;
}

.opacity07 {
    opacity: .7 !important;
}

@-webkit-keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-moz-keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@-o-keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}

@keyframes AnimationName {
    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }
}


.bk-gradient-green {
    background: radial-gradient(rgb(255 255 255 / 0%), rgb(235 227 220), rgb(230 230 225), rgba(222, 247, 243, 0), rgba(222, 247, 243, 0));
    position: absolute;
    width: 330%;
    height: 330%;

}

@-webkit-keyframes greenMove {
    0% {
        top: -90%;
        left: -160%;
    }

    50% {
        top: -40%;
        left: -120%;
    }

    100% {
        top: -90%;
        left: -160%;
    }
}

@-moz-keyframes greenMove {
    0% {
        top: -90%;
        left: -160%;
    }

    50% {
        top: -40%;
        left: -120%;
    }

    100% {
        top: -90%;
        left: -160%;
    }
}

@-o-keyframes greenMove {
    0% {
        top: -90%;
        left: -160%;
    }

    50% {
        top: -40%;
        left: -120%;
    }

    100% {
        top: -90%;
        left: -160%;
    }
}

@keyframes greenMove {
    0% {
        top: -90%;
        left: -160%;
    }

    50% {
        top: -40%;
        left: -120%;
    }

    100% {
        top: -90%;
        left: -160%;
    }
}

.bk-gradient-blue {
    background: radial-gradient(rgba(207, 238, 254, 1), rgba(207, 238, 254, .3), rgba(207, 238, 254, .1), rgba(207, 238, 254, 0), rgba(207, 238, 254, 0));
    position: absolute;
    width: 330%;
    height: 330%;

}

@-webkit-keyframes blueMove {
    0% {
        top: -150%;
        left: -90%;
    }

    50% {
        top: -100%;
        left: -120%;
    }

    75% {
        top: -170%;
        left: -110%;
    }

    100% {
        top: -150%;
        left: -90%;
    }
}

@-moz-keyframes blueMove {
    0% {
        top: -150%;
        left: -90%;
    }

    50% {
        top: -100%;
        left: -120%;
    }

    75% {
        top: -170%;
        left: -110%;
    }

    100% {
        top: -150%;
        left: -90%;
    }
}

@-o-keyframes blueMove {
    0% {
        top: -150%;
        left: -90%;
    }

    50% {
        top: -100%;
        left: -120%;
    }

    75% {
        top: -170%;
        left: -110%;
    }

    100% {
        top: -150%;
        left: -90%;
    }
}

@keyframes blueMove {
    0% {
        top: -150%;
        left: -90%;
    }

    50% {
        top: -100%;
        left: -120%;
    }

    75% {
        top: -170%;
        left: -110%;
    }

    100% {
        top: -150%;
        left: -90%;
    }
}

.bk-gradient-blue2 {
    background: radial-gradient(rgba(207, 238, 254, 1), rgba(207, 238, 254, .3), rgba(207, 238, 254, .1), rgba(207, 238, 254, 0), rgba(207, 238, 254, 0));
    position: absolute;
    width: 330%;
    height: 330%;

}

@-webkit-keyframes blueMove2 {
    0% {
        top: -150%;
        left: -290%;
    }

    50% {
        top: -150%;
        left: -180%;
    }

    75% {
        top: -100%;
        left: -180%;
    }

    100% {
        top: -150%;
        left: -290%;
    }
}

@-moz-keyframes blueMove2 {
    0% {
        top: -150%;
        left: -290%;
    }

    50% {
        top: -150%;
        left: -180%;
    }

    75% {
        top: -100%;
        left: -180%;
    }

    100% {
        top: -150%;
        left: -290%;
    }
}

@-o-keyframes blueMove2 {
    0% {
        top: -150%;
        left: -290%;
    }

    50% {
        top: -150%;
        left: -180%;
    }

    75% {
        top: -100%;
        left: -180%;
    }

    100% {
        top: -150%;
        left: -290%;
    }
}

@keyframes blueMove2 {
    0% {
        top: -150%;
        left: -290%;
    }

    50% {
        top: -150%;
        left: -180%;
    }

    75% {
        top: -100%;
        left: -180%;
    }

    100% {
        top: -150%;
        left: -290%;
    }
}


* {
    // outline: 1px solid red;
}

body {
    padding: 0;
    margin: 0;
}

// ------ . ------

p {
    margin: 0px;
}


// ------ buttons ------
.btn-1 {
    text-decoration: none;
    color: black;
    background-color: $blue;
    padding: 0px 30px 0px 30px;
    height: 52px;
    font-family: "Lexend";
    font-weight: $semibold-weigth;
    border: none;
    border-radius: 30px;
    width: fit-content;
    display: flex;
    align-items: center;
    margin: 5px 0px;
    cursor: pointer;
    white-space: nowrap;
    letter-spacing: .1em;

    &.disable {
        opacity: 30%;
    }
}

.btn-1:hover {
    @media (min-width: 900px) {
        background-color: #C3EFFF;
        color: #575757;
        color: #fff;
        background-color: #000;
    }
}

.btn-2 {
    text-decoration: none;
    color: black;
    display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0px 30px 0px 30px;
    height: 52px;
    font-family: "Lexend";
    font-weight: $semibold-weigth;
    border: 3px solid #000;
    border-radius: 30px;
    cursor: pointer;
    white-space: nowrap;
    width: fit-content;
    letter-spacing: .1em;
}

.btn-2:hover {
    @media (min-width: 900px) {
        color: #fff;
        background-color: #000;
    }
}

.btn-3 {
    color: black;
    text-decoration: none;
    background-color: $blue;
    padding: 0px 60px 0px 60px;
    height: 52px;
    font-family: "Lexend";
    font-weight: $semibold-weigth;
    border: none;
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    white-space: nowrap;
    letter-spacing: .1em;

    &.disable {
        opacity: 30%;
    }
}

.btn-3:hover {
    @media (min-width: 900px) {

        color: #fff;
        background-color: #000;
    }
}

.btn-4 {
    color: black;
    text-decoration: none;
    padding: 0px 60px 0px 60px !important;
    opacity: 1;
    backdrop-filter: blur(11px);
    -webkit-backdrop-filter: blur(11px);
    height: 89px;
    font-family: "Lexend";
    font-weight: $bold-weigth;
    border: 4px solid #FFFFFF66;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    cursor: pointer;
    white-space: nowrap;
    white-space: nowrap;
    background-color: rgba(256, 256, 256, 0.26);

    @media (max-width: $mobile-size) {
        border: solid #FFFFFF66 2px;
        padding: 0px 30px 0px 30px !important;
    }
}

.btn-4:hover {
    @media (min-width: 900px) {
        color: black;
        border: 4px solid #85DFFF80;

        img {
            filter: none !important;
        }

    }
}

@media (max-width: $tablet-size) {
    .btn-1 {
        height: 42px;
        font-size: 1em;

        &.disable {
            opacity: 30%;
        }
    }

    .btn-2 {
        height: 42px;
        font-size: 1em;
    }

    .btn-3 {
        background-color: $blue;
        padding: 0px 20% 0px 20%;
        height: 42px;
        font-weight: $semibold-weigth;
        font-size: 1em;
        border: none;
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0px 20px;
        cursor: pointer;
        white-space: nowrap;

        &.disable {
            opacity: 30%;
        }
    }
}

.loading-img {
    background-image: url('./assets/img/pastilla_liceo.png');
    background-repeat: no-repeat;
    background-size: 50px;
    width: 50px;
    height: 52px;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background-position-x: center;
    width: fit-content;
    color: transparent;
    padding: 0px 30px;
    margin: 5px 0px;
    font-family: "Lexend";
    font-weight: 600;
    font-size: 1.14em;
    letter-spacing: 0.2em;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

// ------ . ------
// ------ links ------
.link-1 {
    font-family: "Lexend";
    font-weight: $semibold-weigth;
    font-size: 0.9375em;
    text-decoration: underline;

    &.lowercase {
        text-transform: lowercase;
    }
}

.link-2 {
    font-family: "Lexend";
    font-weight: $semibold-weigth;
    font-size: 1.125em;
    text-decoration: underline;
}

@media (max-width: $mobile-size) {
    .link-1 {
        font-family: "Lexend";
        font-weight: $semibold-weigth;
        font-size: 0.9375em;
        text-decoration: underline;

        &.lowercase {
            text-transform: lowercase;
        }
    }

    .link-2 {
        font-family: "Lexend";
        font-weight: $semibold-weigth;
        font-size: 0.75em;
        text-decoration: underline;
    }
}

// ------ . ------
// ------ glass ------
.glass-1 {
    background-color: rgba(256, 256, 256, 0.26);
    border: solid $white 4px;

    @media (max-width: $mobile-size) {
        border: solid $white 2px;
    }

    // border-radius: 8px;
    opacity: 1;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
}

.glass-2 {
    background-color: rgba(256, 256, 256, 0.64);
    border: solid $white 2px;
    // border-radius: 8px;
    opacity: 1;
    backdrop-filter: blur(25px);
    -webkit-backdrop-filter: blur(25px);
}

// ------ . ------
// ------ box ------
.box {
    border: solid $black $line-height;
    padding: 5px;
    width: fit-content;
}

// ------ . ------

.underlined {
    background: none;
    outline: none;
    border: none;
    width: fit-content;
}

.underlined::after {
    content: '';
    height: 8px;
    width: 100%;
    background-color: $blue;
    display: block;
    padding: 0px 5px;
    margin-top: -8px;
    margin-left: -5px;
}

.white-underlined{
    position: relative;
    display: inline-block ;
    z-index: -0;
}

.white-underlined::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 8px;
    background-color: white;
    z-index: -1;
}

.underlined_2 {
    background: none;
    outline: none;
    border: none;
    width: fit-content;

    &::after {
        content: '';
        height: 15px;
        width: 100%;
        background-color: $blue;
        display: block;
        padding: 0px 5px;
        margin-top: -15px;
        margin-left: -5px;
    }

    &.to_white::after {
        background-color: rgb(255, 255, 255);
    }
}



@media (max-width: $mobile-size) {
    .underlined {
        background: none;
        outline: none;
        border: none;
        width: fit-content;
    }

    .underlined::after {
        content: '';
        height: 7px;
        width: 100%;
        background-color: $blue;
        display: block;
        padding: 0px 5px;
        margin-top: -8px;
        margin-left: -5px;
    }

    .underlined_2 {
        background: none;
        outline: none;
        border: none;
        width: fit-content;

        &::after {
            content: '';
            height: 10px;
            width: 100%;
            background-color: $blue;
            display: block;
            padding: 0px 5px;
            margin-top: -15px;
            margin-left: -5px;
        }

        &.to_white::after {
            background-color: rgb(255, 255, 255);
        }
    }

}