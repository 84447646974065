@use "sass:math";
@import './variables/variables.scss';

@function pxToRem($pixel_to_transform) {
    @return #{math.div($pixel_to_transform,14)}rem;
}

@function IfPxToEm($pixel_to_transform) {
    @return #{math.div($pixel_to_transform,16)}rem;
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;700;900&display=swap');

h1 {
    margin: 20px 0px;
}

// --------------new fonts--------------
.light {
    font-weight: $light-weigth;
}

.regular {
    font-weight: $regular-weigth;
}

.medium {
    font-weight: $medium-weigth;
}

.semibold {
    font-weight: $semibold-weigth;
}

.bold {
    font-weight: $bold-weigth;
}

.extrabold {
    font-weight: $extrabold-weigth;
}

.black {
    font-weight: $black-weigth;
}

.underline {
    text-decoration: underline;
}


// --------------new fonts--------------


.lf-supertitle {
    font-size: 23px;

    @media (min-width: $tablet-font-size) {}

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 60px;
    }
}

.lf-maintitle {
    font-size: 17px;

    @media (min-width: $tablet-font-size) {}

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 53px;
    }
}

.lf-subtitle {
    font-size: 20px;

    @media (min-width: $tablet-font-size) {
        font-size: 28px
    }

    @media (min-width: $desktop-font-size) {
        font-size: 36px
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 45px;
    }
}

.lf-second-subtitle {
    font-size: 18px;

    @media (min-width: $tablet-font-size) {
        font-size: 25px;
    }

    @media (min-width: $desktop-font-size) {
        font-size: 30px;
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 40px;
    }
}

.lf-p-1 {
    font-size: 18px;

    @media (min-width: $tablet-font-size) {
        font-size: 24px;
    }

    @media (min-width: $desktop-font-size) {
        font-size: 30px;
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 35px;
    }
}

.lf-p-1-2 {
    font-size: 15px;

    @media (min-width: $tablet-font-size) {
        font-size: 20px;
    }

    @media (min-width: $desktop-font-size) {
        font-size: 25px;
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 30px;
    }
}

.lf-p-2 {
    font-size: 13px;

    @media (min-width: $tablet-font-size) {
        font-size: 17px;
    }

    @media (min-width: $desktop-font-size) {
        font-size: 21px;
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 25px;
    }
}

.lf-p-3 {
    font-size: 13px;

    @media (min-width: $tablet-font-size) {
        font-size: 16px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 20px;
    }
}

.lf-p-4 {
    font-size: 12px;

    @media (min-width: $tablet-font-size) {
        font-size: 16px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 18px;
    }
}

.lf-p-5 {
    font-size: 11px;

    @media (min-width: $tablet-font-size) {
        font-size: 13px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 15px;
    }
}

.lf-p-6 {
    font-size: 11px;

    @media (min-width: $tablet-font-size) {
        font-size: 13px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 13px;
    }
}

.lf-e-1 {
    font-size: 13px;

    @media (min-width: $tablet-font-size) {
        font-size: 18px;
    }

    @media (min-width: 700px) {
        font-size: 25px;
    }

    @media (min-width: $desktop-font-size) {
        font-size: 30px;
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 35px;
    }
}

.lf-e-2 {
    font-size: 18px;

    @media (min-width: $tablet-font-size) {
        font-size: 22px;
    }

    @media (min-width: $desktop-font-size) {
        font-size: 26px;
    }

    @media (min-width: $desktopXL-font-size) {
        font-size: 30px;
    }
}

.lf-e-3 {
    font-size: 15px;

    @media (min-width: $tablet-font-size) {
        font-size: 20px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 25px;
    }
}

.lf-e-4 {
    font-size: 11px;

    @media (min-width: $tablet-font-size) {
        font-size: 15px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 20px;
    }
}

.lf-e-5 {
    font-size: 15px;

    @media (min-width: $tablet-font-size) {
        font-size: 18px;
    }

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 20px;
    }
}

.lf-e-6 {
    font-size: 18px;

    @media (min-width: $tablet-font-size) {}

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 18px;
    }
}

.lf-e-7 {
    font-size: 13px;

    @media (min-width: $tablet-font-size) {}

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {
        font-size: 15px;
    }
}

.lf-btn {
    font-size: 15px;

    @media (min-width: $tablet-font-size) {}

    @media (min-width: $desktop-font-size) {}

    @media (min-width: $desktopXL-font-size) {}

}

// --------------fonts colors --------------
.text-blue{
    color: #85DFFF;
}